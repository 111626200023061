import React, { useEffect } from "react";
import imgSix from "../img/newPhotos/Rectangle 66.png";
// import imgSix from "../img/newPhotos/Rectangle 65 (2)";
import Header from "../header";
import FooterComponent from "../footer";

export default function ServiceCompSeven() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="serviceComp">
      <Header />
      <div className="innerBox">
        <div className="innerInnerBox">
          <h1>Уборка (женщины)</h1>
          <img src={imgSix} alt="#" className="img-fluid" />
          <p>
          Уборка (также чистка) — это комплекс действий, 
          необходимый для обеспечения чистоты в промышленных, 
          коммерческих и бытовых помещениях. Помимо технического 
          обслуживания и аспектов гигиены, она также включает в 
          себя контроль безопасности и порядка, а также улучшения 
          с эстетической точки зрения.
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}
