import React, { useEffect } from "react";
import imgFive from "../img/source/Rectangle 65 (2).png";
import Header from "../header";
import FooterComponent from "../footer";

export default function ServiceCompFive() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <div className="serviceComp">
      <Header />
      <div className="innerBox">
        <div className="innerInnerBox">
          <h1>Квартирный переезд</h1>
          <img src={imgFive} alt="#" className="img-fluid" />
          <p>
            Сборка мебели - это процесс составления отдельных элементов и
            деталей мебели в цельное изделие. Этот процесс может потребовать
            навыков в сборке, инструментов и технического понимания инструкций и
            схем. Вот некоторые общие шаги, которые могут помочь вам при сборке
            мебели: 1. Проверьте все детали: перед началом сборки убедитесь, что
            y вас есть все необходимые детали и инструменты. Распакуйте коробку
            c мебелью и проверьте наличие всех элементов, a также проверьте, нет
            ли повреждений или дефектов. 2. Прочтите инструкции: каждая мебель
            идет c инструкцией по сборке. Внимательно прочтите все инструкции и
            схемы, чтобы понять последовательность действий и правильно
            выполнить сборку. 3. Подготовьте инструменты: удостоверьтесь, что y
            вас есть нужные инструменты для сборки. Это могут быть отвертки,
            молоток, ключи и другие инструменты в зависимости от требований
            конкретной мебели. 4. Собирайте шаг за шагом: начните сборку c
            первого шага, следуя инструкциям. Установите основу или раму мебели
            и продолжайте добавлять и закреплять детали, следуя указаниям. 5.
            Будьте внимательны к подробностям: удостоверьтесь, что каждая деталь
            правильно соединена и закреплена. Проверьте степень затяжки
            соединительных элементов для обеспечения крепкой конструкции. 6.
            Будьте аккуратны: обращайте внимание на очистку поверхности мебели
            от возможной пыли или отпечатков пальцев. Избегайте использования
            излишней силы для избежания повреждений или сдвига деталей. 7.
            Проверьте корректность сборки: после завершения сборки внимательно
            проверьте каждую деталь, чтобы убедиться, что мебель собрана
            правильно и безопасно. Обычно крупные магазины предлагают услуги
            сборки мебели за отдельную плату, если вам необходима
            профессиональная помощь. Это может быть особенно полезно для крупных
            и сложных предметов мебели. Учтите, что время, необходимое для
            сборки мебели, может варьироваться в зависимости от сложности и типа
            мебели. Если вам требуется особенно сложная сборка или y вас есть
            проблемы, связанные c монтажем, рекомендуется обратиться за помощью
            к профессионалам или сервисным центрам.
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}
