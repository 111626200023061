import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customIconUrl from "./img/eastline-black-logo 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const customIcon = L.icon({
  iconUrl: customIconUrl,
  iconSize: [100, 40],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const MapComponent = () => {
  const position = [41.332319, 69.250914];
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div data-aos="fade-up" style={{maxWidth:'1600px',margin:'0px auto'}}>
      <MapContainer
        center={position}
        zoom={13}
        style={{ height: "60vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={customIcon}>
          <Popup>
            <strong>Sebzor ko'chasi (dublyor)</strong> <br />
            <p>Sebzor ko'chasi (dublyor), Toshkent</p>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent;
