import React, { useEffect } from "react";
import RectangleOne from "./img/services/Rectangle 20.png";
import RectangleTwo from "./img/services/Rectangle 24.png";
import RectangleThree from "./img/services/Rectangle 27.png";
import RectangleFour from "./img/services/Rectangle 31.png";
import RectangleFive from "./img/services/Rectangle 32.png";
import RectangleSix from "./img/newPhotos/Rectangle 68.png";
import RectangleSeven from "./img/newPhotos/Rectangle 72.png";
import RectangleEight from "./img/newPhotos/Rectangle 73.png";
import { motion } from "framer-motion";

import Aos from "aos";
import "aos/dist/aos.css";

import { useNavigate } from "react-router-dom";
export default function SixthComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  let navigate = useNavigate();
  return (
    <div className="sixthComp" id="sixth" data-aos="fade-up">
      <h1>
        <div className="sixthTop">
          <h1>Услуги</h1>
        </div>
        <div className="sixthBottom">
          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleOne} alt="#" className="img-fluid" />
            <h1>Квартирный переезд</h1>
            <p>
              Квартирный переезд - это процесс перемещения из одной квартиры
              в...
            </p>
            <button onClick={() => navigate("/service-1")}>Подробнее</button>
          </motion.div>
          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleTwo} alt="#" className="img-fluid" />
            <h1>Офисный переезд</h1>
            <p>
              Офисный переезд - это процесс перемещения офиса или рабочего...
            </p>
            <button onClick={() => navigate("/service-2")}>Подробнее</button>
          </motion.div>
          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleThree} alt="#" className="img-fluid" />
            <h1>Услуги грузчиков</h1>
            <p>
              Наши профессиональные грузчики имеют большой опыт
              транспортировки...
            </p>
            <button onClick={() => navigate("/service-3")}>Подробнее</button>
          </motion.div>
          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleFour} alt="#" className="img-fluid" />
            <h1>Грузоперевозки</h1>
            <p>
              Нужна грузовая машина для перевозки? y нас вы можете также
              заказать...
            </p>
            <button onClick={() => navigate("/service-4")}>Подробнее</button>
          </motion.div>
          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleFive} alt="#" className="img-fluid" />
            <h1>Разборка-сборка мебели</h1>
            <p>
              Сборка мебели - это процесс составления отдельных элементов и...
            </p>
            <button onClick={() => navigate("/service-5")}>Подробнее</button>
          </motion.div>

          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleSix} alt="#" className="img-fluid" />
            <h1>Монтаж кондиционера </h1>
            <p>
            Монтаж кондиционирования начинается с определения самого 
            удобного места...
            </p>
            <button onClick={() => navigate("/service-6")}>Подробнее</button>
          </motion.div>
          <motion.div
            className="box"
            whileHover={{
              scale: 1.05,
            }}
          >
            <img src={RectangleSeven} alt="#" className="img-fluid" />
            <h1>Уборка (женщины)</h1>
            <p>
            Уборка (также чистка) — это комплекс действий, 
            необходимый для обеспечения чистоты...
            </p>
            <button onClick={() => navigate("/service-7")}>Подробнее</button>
          </motion.div>
        </div>
      </h1>
    </div>
  );
}
