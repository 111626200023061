import React, { useEffect } from "react";
import imgOne from "./img/Rectangle 10.png";
import checkSymbol from "./img/material-symbols_check.png";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ThirdComponent() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const [text] = useTypewriter({
    words: ["заботимся каждой детали клиентов!"],
    loop: {},
  });
  return (
    <div>
      <div className="thirdComponent" id="third" data-aos="fade-up">
        <div className="thirdLeft">
          <img src={imgOne} alt="#" className="img-fluid" />
        </div>

        <div className="thirdRight">
          <div className="rightTop">
            <h1>Немного o нас</h1>
            <h5>Мы заботимся о каждой детали в работе с клиентом!</h5>
            <p>
              Компания основана 2017 году в Ташкенте! Мы до сих пор работали co
              многими как юридическими так и физическими лицами!Главной нашей
              задачей была оказать качественную услугу.B итоге мы имеем
              многодовольных клиентов партнеров!
            </p>
          </div>
          <div className="rightBottom">
            <div className="rightBottomLeft">
              <h5>Как мы работаем?</h5>
              <p>
                <img src={checkSymbol} alt="#" /> Качественно
              </p>
              <p>
                <img src={checkSymbol} alt="#" /> Надежно
              </p>
              <p>
                <img src={checkSymbol} alt="#" /> Быстро
              </p>
              <p>
                <img src={checkSymbol} alt="#" /> He дорого
              </p>
            </div>
            {/* <div className="rightBottomRight">
              <p>Успешных перевозок</p>
              <p>Сотрудников</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
