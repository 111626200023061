import React, { useEffect } from "react";
import imgSix from "../img/newPhotos/Rectangle 65.png";
// import imgSix from "../img/newPhotos/Rectangle 65 (1)";
// import imgSix from "../img/newPhotos/Rectangle 65 (2)";
import Header from "../header";
import FooterComponent from "../footer";

export default function ServiceCompSix() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="serviceComp">
      <Header />
      <div className="innerBox">
        <div className="innerInnerBox">
          <h1>Кондиционер монтаж</h1>
          <img src={imgSix} alt="#" className="img-fluid" />
          <p>
          Монтаж кондиционирования начинается с определения самого 
          удобного места с учетом всех требований заказчика. От того, 
          на сколько оптимально будет расположен кондиционер, на 
          сколько правильно будет подобрана мощность, тип и надежность 
          оборудования, и будет зависеть степень достижения комфортного 
          микроклимата в условиях повышения температуры.
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}
