import React from "react";
import FirstComponent from "./components/firstComponent";
import Header from "./components/header";
import SecondComponent from "./components/secondComponent";
import ThirdComponent from "./components/thirdComponent";
import FourthComponent from "./components/fourthComponent";
import FifthComponent from "./components/fifthComponent";
import SixthComponent from "./components/sixthComponent";
import FooterComponent from "./components/footer";
import MapComponent from "./components/leaflet";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

export default function MainApp() {
  useEffect(()=>{
     Aos.init({duration:2000})
  },[])
  return <div>
        <Header />
      <FirstComponent />
      <SecondComponent />
      <ThirdComponent />
      <FourthComponent />
      <FifthComponent />
      <SixthComponent />
      <MapComponent/>
      <FooterComponent/>
  </div>;
}
