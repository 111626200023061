import React, { useEffect } from "react";
import imgOne from "./img/whyUsImg/Rectangle 5.png";
import imgTwo from "./img/whyUsImg/Rectangle 6.png";
import imgThree from "./img/whyUsImg/Rectangle 7.png";
import imgFour from "./img/whyUsImg/Rectangle 8.png";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";

export default function SecondComponent() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <div className="secondComponent" id="second" data-aos="fade-up">
        <div className="whyUs">
          <h1>Почему мы?</h1>
        </div>
        <div className="whyUsImages">
          <motion.div
            className="whyUsImg1 whyUsImg"
            whileHover={{
              scale: 1.1,
            }}
          >
            <img src={imgOne} alt="#" />
            <p>Бережно относимся к чужому имуществу</p>
          </motion.div>
          <motion.div
            className="whyUsImg2 whyUsImg"
            whileHover={{
              scale: 1.1,
            }}
          >
            <img src={imgTwo} alt="#" />
            <p>Организуем качественный и быстрый переезд</p>
          </motion.div>
          <motion.div
            className="whyUsImg3 whyUsImg"
            whileHover={{
              scale: 1.1,
            }}
          >
            <img src={imgThree} alt="#" />
            <p>Доставим груз точно в срок</p>
          </motion.div>
          <motion.div
            className="whyUsImg4 whyUsImg"
            whileHover={{
              scale: 1.1,
            }}
          >
            <img src={imgFour} alt="#" />
            <p>Опытные и  проверенные сотрудники</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
